<template>
  <div class="help-container">
      <img :src="warrantyImage" width="285"/>
      <h2>Construction Warranty</h2>
      <p>Please see our <Button type="text" class='underline'><router-link to="/warranty">Warranty</router-link></Button> policies for more information.</p>
      </div>
</template>

<script>
import Button from '@/components/buttons/Button'
export default {
  components: { Button },
  props: { warrantyImage: String }
}
</script>

<style lang="scss">
    .help-container{
        margin-top: 30px;
      h2{
        text-transform: uppercase;
      }
    }
</style>
