<template>
  <div class="build-specs-container">
    <div class="left">
      <div class="diy">
        <h2>DO IT YOURSELF</h2>
        <p>
        Bolt-on installation. May require basic automotive knowledge for proper installation and professional installation is always recommended.
        </p>
        <a v-if="installationGuide" :href="installationGuide" target='_blank'>
        <Button type="text" class="underline" >Download the installation Guide</Button>
     </a>
      </div>
      <div class="installation">
        <div class="progress-bar">
          <div class="progress"></div>
        </div>
        <div class="installation-text">
          <div class="left-spec">
            <span>Bolt-on</span>
            <span>Do it yourself</span>
          </div>
          <div class="right-spec">
            <span>Professional Installation Recommended</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <ExpandingList customClass="spec-list" v-if="specData" :data="specData" />
    </div>
  </div>
</template>

<script>

import ExpandingList from '@/components/ExpandingList'
import Button from '@/components/buttons/Button'
export default {
  components: { Button, ExpandingList },
  props: { specData: Object, installationGuide: String },
  setup () {
    return { }
  }
}
</script>

<style scoped lang="scss">
.build-specs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  margin: 30px 0;
  .left {
    max-width: 440px;
    .installation {
      margin-top: 60px;
      .installation-text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 150px;
          flex-direction: column;
          display: flex;
          &.right-spec {
            text-align: right;
          }
        }
      }
      .progress-bar {
        width: 100%;
        height: 15px;
        border: black solid thin;
        margin-bottom: 10px;
        .progress {
          width: 25%;
          background-color: black;
          height: 100%;
        }
      }
    }
  }
  .right {
    min-width: 270px;
  }
}
</style>
