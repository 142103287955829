<template>
  <div class="gallery-container">
    <GallerySlider containerClass="gallery" :data="galAssets" :activeIndex="activeIndex" :arrows="false" />
    <GallerySlider containerClass="thumbs" :data="galAssets" :arrows="false" />
  </div>
</template>
<script>
import 'swiper/swiper-bundle.css'
import Swiper from 'swiper/swiper-bundle.min.js'

import GallerySlider from './GallerySlider'
import { onMounted, ref } from 'vue'

export default {
  props: { galAssets: Array },
  components: { GallerySlider },
  setup (props) {
    const gallery = ref(null)
    const thumbs = ref(null)
    const activeIndex = ref(0)

    const player = ref(null)
    const isVideo = (_asset) => {
      return _asset.includes('mp4')
    }
    onMounted(() => {
      thumbs.value = new Swiper('.thumbs', {
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        direction: 'vertical'
      })
      gallery.value = new Swiper('.gallery', {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        thumbs: {
          swiper: thumbs.value
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        }
      })
      gallery.value.on('slideChange', function (e) {
        const galIndex = gallery.value.activeIndex
        const galAsset = props.galAssets[galIndex]
        if (isVideo(galAsset)) {
          const vidId = `slide-video-${galIndex}`
          player.value = document.getElementById(vidId)
          player.value.currentTime = 0
          player.value.play()
        } else if (player.value)player.value.pause()
      })
    })
    return { activeIndex }
  }
}
</script>
<style lang="scss">
.gallery-container {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  .swiper{
    margin:0px;
  }
  .gallery {
    max-width: 580px;
    height: 500px;
  }
  .thumbs {
    width: 270px;
    min-width: 180px;
    height: 500px;
    .swiper-slide{
      width: 136px;
      height: 136px !important;
      margin-bottom: 24px !important;
    }
  }
  .swiper-slide{
     img {
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 100%;
    margin: auto;
    cursor: pointer;
  }
  }
}
</style>
