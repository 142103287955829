<template>
  <div class="compatible-vehicle-container">
    <h2>COMPATIBLE VEHICLES</h2>
    <p>
      More information may be necessary to guarantee this part fits these
      vehicles. Click on a vehicle and finish filling out the listed
      requirements to confirm fit.
    </p>
    <nav class="alphabet">
      <span
        v-for="letter in lettersArr"
        :key="letter.letter"
        :id="'nav-item-' + letter.letter"
        :class="{ valid: letter.valid, chosen: letter.chosen }"
        @click="navItemClicked(letter.letter, letter.valid)"
      >
        {{ letter.letter }}
      </span>
    </nav>
    <div class="tables-wrapper" v-if="chosenSectionData.data">
      <MakeTable
        v-for="(makeArr) in chosenSectionData.data"
        :key="makeArr[0].Make"
        :title="makeArr[0].Make"
        :data="makeArr"
      />
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import MakeTable from '@/components/pdp/compatibleVehicles/MakeTable'

export default {
  props: {
    compatibleVehicleData: { type: Array, default: () => [] }
  },
  components: { MakeTable },
  setup (props) {
    const letters = 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z'
      .split(' ')
      .map((letter) => {
        return { letter, valid: false, chosen: false }
      })
    const lettersArr = ref(letters)
    const chosenSectionData = ref([])

    // request data when we get our props
    watchEffect(async () => {
      if (props.compatibleVehicleData.length > 0) {
        lettersArr.value = letters.map((letterObj) => {
          const dataHasLetter = props.compatibleVehicleData.findIndex(
            (tableLetter) =>
              tableLetter.letter === letterObj.letter.toLowerCase()
          )
          if (dataHasLetter >= 0) letterObj.valid = true
          return letterObj
        })

        chosenSectionData.value = props.compatibleVehicleData[0]
      }
    })

    // update active letter selection when 'chosenSectionData' changes
    watchEffect(() => {
      const activeLetter = chosenSectionData.value?.letter || ''
      lettersArr.value = lettersArr.value.map((_letterObj) => {
        _letterObj.chosen = false
        if (_letterObj.letter.toLowerCase() === activeLetter) { _letterObj.chosen = true }
        return _letterObj
      })
    })

    const navItemClicked = (_letter, _isValid) => {
      if (_isValid) {
        const chosenData = props.compatibleVehicleData.find((_letterObj) => {
          return _letterObj.letter === _letter.toLowerCase()
        })
        chosenSectionData.value = chosenData
      }
    }

    return { lettersArr, chosenSectionData, navItemClicked }
  }
}
</script>

<style lang="scss">
.compatible-vehicle-container {
  p {
    max-width: 500px;
    margin: 0 auto;
    font-size: 13px;
    line-height: 22px;
  }
  .alphabet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 570px;
    margin: 30px auto;
    cursor: default;
    span {
      opacity: 0.4;
      &.valid {
        color: var(--link-color) !important;
        opacity: 1;
        cursor: pointer;
      }
      &.chosen {
        @include mf-bold(inherit);
        color: black !important;
        cursor: default;
      }
    }
  }
}
</style>
