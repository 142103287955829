<template>
  <div
    style="--swiper-navigation-color: #000; --swiper-pagination-color: #000"
    class="swiper"
    :class="containerClass"
  >
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(slideContent, index) in data"
        :key="slideContent"
      >
        <video
          v-if="isVideo(slideContent)"
          :id="'slide-video-' + index"
          class="video"
          controls
          preload="auto"
          data-setup="{}"
        >
          <source :src="slideContent" type="video/mp4" />
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">
              supports HTML5 video
            </a>
          </p>
        </video>
        <img v-else-if="isVideoInThumbs(slideContent)" :src="videoThumb" />
        <img v-else :src="slideContent" />
      </div>
    </div>
    <div v-if="arrows" class="swiper-button-next"></div>
    <div v-if="arrows" class="swiper-button-prev"></div>
  </div>
</template>

<script>
export default {
  props: {
    containerClass: String,
    data: Array,
    arrows: { type: Boolean, default: true },
    activeIndex: Number
  },
  setup (props) {
    const videoThumb = require('@/assets/playIcon.png')
    const isVideo = (_slideUrl) => {
      return _slideUrl.includes('mp4') && props.containerClass !== 'thumbs'
    }
    const isVideoInThumbs = (_slideUrl) => {
      return _slideUrl.includes('mp4') && props.containerClass === 'thumbs'
    }

    return { isVideo, videoThumb, isVideoInThumbs }
  }
}
</script>

<style lang="scss">
.video {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
