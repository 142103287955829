<template>
  <div class="expanding-list-container" :class="customClass">
    <h2>SPECIFICATIONS</h2>
    <div class="list-container">

      <div v-for="(spec, index) in data.specData" :key="index + spec.AttributeID + spec.Text"
       class="list-item" :class="index > 5 ? `${specListItemToggle} below` : ''">
        <span class="label">{{spec.AttributeID}}</span>
        <span class="value">{{spec.Text}}</span>
      </div>

    </div>
    <Button v-if='data.specData.length >= 6' type="text" class="underline" @click="seeMoreSpecsClicked"  style="cursor:pointer">{{ specButtonLabel }}</Button>
  </div>
</template>

<script>
import { ref } from 'vue'
import gsap from 'gsap'
import Button from '@/components/buttons/Button'
const DEFAULT_SPEC_BUTTON_LABEL = 'See more specifications'
const CLICKED_SPEC_BUTTON_LABEL = 'Hide specifications'

export default {
  components: { Button },
  props: {
    customClass: String,
    data: Object
  },
  setup (props) {
    const specButtonLabel = ref(DEFAULT_SPEC_BUTTON_LABEL)
    const specListItemToggle = ref('hidden')
    const seeMoreSpecsClicked = (e) => {
      if (specButtonLabel.value === DEFAULT_SPEC_BUTTON_LABEL) {
        specButtonLabel.value = CLICKED_SPEC_BUTTON_LABEL

        gsap.to('.list-item.below', {
          duration: 0.5,
          autoAlpha: 1,
          onStart: () => {
            specListItemToggle.value = ''
          }
        })
      } else {
        gsap.to('.list-item.below, .spec-list a', {
          duration: 0.5,
          autoAlpha: 0,
          onComplete: () => {
            specListItemToggle.value = 'hidden'
            specButtonLabel.value = DEFAULT_SPEC_BUTTON_LABEL
          }
        })

        gsap.to('.spec-list a', {
          duration: 0.5,
          autoAlpha: 1,
          delay: 0.5
        })
      }
    }
    return { seeMoreSpecsClicked, specButtonLabel, specListItemToggle }
  }
}
</script>

<style scoped lang="scss">
.list-container {
  margin-bottom: 30px;
  min-width: 210px;
  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
    .label {
      max-width: 145px;
      font-weight: bold;
    }
    .value {
      font-size: 12px;
      vertical-align: center;
      display: inline-block;
      line-height: 2em;
      max-width:140px;
      text-align: right;
    }
  }
}
</style>
